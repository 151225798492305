import { string } from "prop-types"
import React, { useState } from "react"

import "./Accordion.scss"

const Accordion = props => {
  const [isShow, setIsShow] = useState(false)

  const onClick = () => {
    setIsShow(!isShow)
  }

  return (
    <div className="Accordion">
      <div className="Accordion__header">
        <button className="Accordion__headerButton" onClick={onClick}>
          <span className="Accordion__headerTitle">{props.title}</span>
          <i
            className={`Accordion__headerIcon${
              isShow ? " Accordion__headerIcon--show" : ""
            }`}
          />
        </button>
      </div>
      {isShow && <div className="Accordion__panel">{props.children}</div>}
    </div>
  )
}

Accordion.prototype = {
  title: string,
}

export default Accordion
