import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import ButtonLink from "../Button/ButtonLink"
import LogoAnin from "../../images/header/logo-anin"
import LogoAninWhite from "../../images/header/logo-aninWhite"

import "../../components/Navigation/Navigation.scss"
import "./Header.scss"
import IconMenuPink from "../../images/header/icon-menuPink"
import IconMenuWhite from "../../images/header/icon-menuWhite"

const Header = props => {
  /** ナビゲーションの表示 */
  const [show, setShow] = useState(false)
  /** デバイス */
  const [device, setDevice] = useState("sp")
  /** スクロール中 */
  const [isScroll, setIsScroll] = useState(false)

  const toggleNav = () => {
    setShow(!show)
  }

  const scrollNavigation = id => {
    setShow(false)
    const target = document.querySelector(`#${id}`)
    const titleHeight = device === "pc" ? 110 : 60
    const targetY =
      target.getBoundingClientRect().top + window.pageYOffset - titleHeight
    window.scrollTo({ top: targetY, left: 0, behavior: "smooth" })
  }

  /** パス/デバイスの変更 */
  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  useEffect(() => {
    const scrollAction = () => {
      if (650 < window.scrollY) {
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    }

    window.addEventListener("scroll", scrollAction, {
      capture: false,
      passive: true,
    })
    scrollAction()

    return () => {
      window.removeEventListener("scroll", scrollAction)
    }
  })

  return (
    <header
      className={`Header Header--${device}${
        isScroll ? " Header--isScroll" : ""
      }`}
    >
      <div className="Header__inner">
        <h1>
          <Link to="/">
            <div className="Header__logo">
              <LogoAnin width="134" height="27" />
            </div>
          </Link>
        </h1>
        {device === "sp" && (
          <button onClick={toggleNav} className="Header__navButton">
            <IconMenuPink />
          </button>
        )}
        {device === "pc" && (
          <div className="Header__nav">
            <nav>
              <ul className="Header__navList">
                <li className="Header__nav">
                  <button
                    className="Header__navButton"
                    onClick={() => scrollNavigation("About")}
                  >
                    <span>弊社について</span>
                  </button>
                </li>
                <li className="Header__nav">
                  <button
                    className="Header__navButton"
                    onClick={() => scrollNavigation("Service")}
                  >
                    <span>サービス内容</span>
                  </button>
                </li>
                <li className="Header__nav">
                  <button
                    className="Header__navButton"
                    onClick={() => scrollNavigation("CaseStudy")}
                  >
                    <span>導入事例</span>
                  </button>
                </li>
                <li className="Header__nav">
                  <button
                    className="Header__navButton"
                    onClick={() => scrollNavigation("Greeding")}
                  >
                    <span>代表あいさつ</span>
                  </button>
                </li>
                <li className="Header__nav">
                  <button
                    className="Header__navButton"
                    onClick={() => scrollNavigation("Company")}
                  >
                    <span>会社概要</span>
                  </button>
                </li>
                <li className="Header__nav Header__nav--contact">
                  <ButtonLink
                    href={`${process.env.ANIN_PATH}/pdf/price.pdf`}
                    target
                    backdrop
                    color="blue"
                    text="料金表"
                  />
                </li>
                <li className="Header__nav Header__nav--contact">
                  <ButtonLink
                    onClick={() => scrollNavigation("Contact")}
                    target
                    color="red"
                    backdrop
                    text="お問い合わせ・お見積り"
                  />
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>

      {device === "sp" && show && (
        <div className="Navigation">
          <div className="Header__inner">
            <h1>
              <Link to="/">
                <LogoAninWhite width="134" height="27" />
              </Link>
            </h1>
            <button onClick={toggleNav} className="Header__navButton">
              {/* <StaticImage
                src="../../images/header/icon-menuWhite.svg"
                alt="ナビゲーション"
                width={26}
                loading="lazy"
                className="Header__menuIcon"
                placeholder="none"
              /> */}
              <IconMenuWhite />
            </button>
          </div>

          <nav className="Navigation__nav">
            <ul>
              <li className="Navigation__navItem">
                <button
                  className="Navigation__navItemButton"
                  onClick={() => scrollNavigation("Main")}
                >
                  <span className="Navigation__navItemTitle">TOP</span>
                  <span className="Navigation__navItemSubText">トップ</span>
                </button>
              </li>
              <li className="Navigation__navItem">
                <button
                  className="Navigation__navItemButton"
                  onClick={() => scrollNavigation("About")}
                >
                  <span className="Navigation__navItemTitle">ABOUT US</span>
                  <span className="Navigation__navItemSubText">
                    私たちについて
                  </span>
                </button>
              </li>
              <li className="Navigation__navItem">
                <button
                  className="Navigation__navItemButton"
                  onClick={() => scrollNavigation("Service")}
                >
                  <span className="Navigation__navItemTitle">SERVICE</span>
                  <span className="Navigation__navItemSubText">事業内容</span>
                </button>
              </li>
              <li className="Navigation__navItem">
                <button
                  className="Navigation__navItemButton"
                  onClick={() => scrollNavigation("CaseStudy")}
                >
                  <span className="Navigation__navItemTitle">CASE STUDY</span>
                  <span className="Navigation__navItemSubText">事業内容</span>
                </button>
              </li>
              <li className="Navigation__navItem">
                <button
                  className="Navigation__navItemButton"
                  onClick={() => scrollNavigation("Greeding")}
                >
                  <span className="Navigation__navItemTitle">GREEDING</span>
                  <span className="Navigation__navItemSubText">
                    代表あいさつ
                  </span>
                </button>
              </li>
              <li className="Navigation__navItem">
                <button
                  className="Navigation__navItemButton"
                  onClick={() => scrollNavigation("Company")}
                >
                  <span className="Navigation__navItemTitle">COMPANY</span>
                  <span className="Navigation__navItemSubText">会社概要</span>
                </button>
              </li>
              <li className="Navigation__navItem Navigation__navItem--contact">
                <button
                  className="Navigation__navContactButton"
                  onClick={() => scrollNavigation("Contact")}
                >
                  <span>お問い合わせ・お見積り</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  )
}

export default Header
