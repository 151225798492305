import { string } from "prop-types"
import React from "react"

const LogoAnin = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 104.037 21.367">
    <g data-name="グループ 7848">
      <title>ANIN Co.</title>
      <path fill="#fff" d="M.551 20.59h2.523a.874.874 0 0 0 .841-.58l1.421-3.132h8.671l1.421 3.132a.834.834 0 0 0 .841.58h2.523a.512.512 0 0 0 .493-.754L10.358.319a.493.493 0 0 0-.5-.319h-.29a.521.521 0 0 0-.493.319L.058 19.836a.512.512 0 0 0 .493.754Zm6.207-6.931L9.6 7.279h.087l2.9 6.38Zm15.166 6.38a.57.57 0 0 0 .551.551h2.639a.551.551 0 0 0 .551-.551V7.569h.029L37.816 20.88h.7a.545.545 0 0 0 .542-.522V.841a.57.57 0 0 0-.551-.551h-2.663a.551.551 0 0 0-.551.551v11.977h-.029L23.2 0h-.725a.545.545 0 0 0-.551.522Zm22.417 0a.57.57 0 0 0 .551.551h2.7a.57.57 0 0 0 .551-.551V.839a.57.57 0 0 0-.551-.551h-2.7a.57.57 0 0 0-.551.551Zm9.077 0a.57.57 0 0 0 .551.551h2.639a.551.551 0 0 0 .55-.551V7.569h.029L69.31 20.88h.7a.545.545 0 0 0 .548-.522V.841a.57.57 0 0 0-.551-.551h-2.669a.551.551 0 0 0-.551.551v11.977h-.029L54.694 0h-.725a.545.545 0 0 0-.551.522Z" data-name="パス 42708"/>
      <path fill="#ff6c89" d="M79.394 14.546a6.812 6.812 0 0 0 6.859 6.821 6.521 6.521 0 0 0 4.617-1.843.362.362 0 0 0 .019-.513l-1.178-1.216a.35.35 0 0 0-.475 0 4.56 4.56 0 0 1-2.902 1.082 4.3 4.3 0 0 1-4.261-4.388 4.308 4.308 0 0 1 4.237-4.427 4.5 4.5 0 0 1 2.924 1.121.3.3 0 0 0 .475 0l1.159-1.2a.353.353 0 0 0-.019-.532 6.43 6.43 0 0 0-4.6-1.767 6.844 6.844 0 0 0-6.855 6.862Zm12.5 2.812a3.975 3.975 0 0 0 3.897 4.009 3.975 3.975 0 0 0 3.9-4.009 3.966 3.966 0 0 0-3.9-3.971 3.966 3.966 0 0 0-3.895 3.971Zm2.071 0a1.859 1.859 0 0 1 1.824-1.919 1.871 1.871 0 0 1 1.824 1.919 1.893 1.893 0 0 1-1.824 1.957 1.881 1.881 0 0 1-1.822-1.957Zm6.935 2.451a1.565 1.565 0 0 0 1.56 1.558 1.581 1.581 0 0 0 1.574-1.558 1.6 1.6 0 0 0-1.577-1.577 1.581 1.581 0 0 0-1.555 1.577Z" data-name="パス 42709"/>
    </g>
  </svg>
)

LogoAnin.prototype = {
  width: string,
  height: string,
}

export default LogoAnin