import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"

import "./Map.scss"

const Map = props => (
  <LoadScript
    // TODO: Access Keyが設楽個人のアカウントなので後ほど変更必要
    // googleMapsApiKey=`${process.envGATSBY_GOOGLE_MAP_API_KEY}`>
    googleMapsApiKey="AIzaSyBrV2EaL7Y4GTaMgQLT21AiLEeukW56OHk"
  >
    <div className="Map">
      <GoogleMap
        // 初期表示時の中心地の座標
        center={{ lat: props.lat, lng: props.lng }}
        // 初期表示時のズームの度合いを設定(完成したMapは15を設定)
        zoom={props.zoom}
        zoomControl
      >
        <Marker
          position={{ lat: props.lat, lng: props.lng }}
          icon={{
            url: `${process.env.IMAGE_PATH}footer/img-mapPinSmall.png`
          }}
        />
      </GoogleMap>
      {props.address && (
        <p className="Map__address">
          <span className="Map__addressTitle">会社住所</span>
          <address>{props.address}</address>
        </p>
      )}
    </div>
  </LoadScript>
)

export default Map
