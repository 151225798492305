import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Accordion from "../../components/Accordion/Accordion"
import TopTransition from "../../components/TopTransition/TopTransition"
import Map from "../../components/Map/Map"
import LogoAnin from "../../images/footer/sp/logo-anin"

import "./Footer.scss"
import IconInstagram from "../../images/footer/sp/icon-instagram"
import IconTiktok from "../../images/footer/sp/icon-tiktok"

const Footer = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const scrollNavigation = id => {
    const target = document.querySelector(`#${id}`)
    const titleHeight = device === "pc" ? 110 : 60
    const targetY =
      target.getBoundingClientRect().top + window.pageYOffset - titleHeight
    window.scrollTo({ top: targetY, left: 0, behavior: "smooth" })
  }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <footer className={`Footer Footer--${device}`}>
      {device === "sp" ? (
        <React.Fragment>
          <div className="Footer__topTransition">
            <TopTransition />
          </div>

          <div className="Footer__section Footer__section--divider">
            <button
              className="Footer__space"
              onClick={() => scrollNavigation("Company")}
            >
              COMPANY
            </button>
          </div>
          <div className="Footer__section">
            <button
              className="Footer__space"
              onClick={() => scrollNavigation("Service")}
            >
              SERVICE
            </button>
          </div>
          <div className="Footer__section">
            <a
              href={`${process.env.ANIN_PATH}/pdf/price.pdf`}
              target="_blank"
              className="Footer__space"
            >
              PRICE
            </a>
          </div>
          <div className="Footer__section">
            <Accordion title="CONTACT">
              <ul role="list">
                <li>
                  <button
                    className="Footer__space"
                    onClick={() => scrollNavigation("Contact")}
                  >
                    お問い合わせ
                  </button>
                </li>
                <li>
                  <button
                    className="Footer__space"
                    onClick={() => scrollNavigation("Company")}
                  >
                    会社概要
                  </button>
                </li>
              </ul>
            </Accordion>
          </div>
          <div className="Footer__section">
            <a href={process.env.ANIN_PATH}>
              <div className="Footer__logo">
                <LogoAnin width="105" height="21.19" />
              </div>
            </a>
          </div>
          <div className="Footer__section Footer__section--noDivider">
            <Map
              lat={35.46471}
              lng={139.615588}
              zoom={17}
              address="〒220-0005 神奈川県横浜市西区南幸2-20-15"
            />
          </div>

          <div className="Footer__snsSection">
            <ul className="Footer__snsList">
              {/* <li className="Footer__snsItem">
                <a href="#dummy" title="Twitter">
                  <StaticImage
                    src="../../images/footer/sp/icon-twitter.svg"
                    width={27}
                    alt="Twitter"
                    loading="lazy"
                  />
                </a>
              </li> */}
              {/* <li className="Footer__snsItem">
                <a href="#dummy" title="Facebook">
                  <StaticImage
                    src="../../images/footer/sp/icon-facebook.svg"
                    height={27}
                    alt="Facebook"
                    loading="lazy"
                  />
                </a>
              </li> */}
              <li className="Footer__snsItem">
                <a
                  href="https://instagram.com/anin.beautyyy"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <StaticImage
                    src="../../images/footer/sp/icon-instagram.svg"
                    width={27}
                    alt="Instagram"
                    loading="lazy"
                    placeholder="none"
                  />
                </a>
              </li>
              <li className="Footer__snsItem">
                <a
                  href="https://www.tiktok.com/@anin.beautyyy"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="TikTok"
                >
                  <StaticImage
                    src="../../images/footer/sp/icon-tiktok.svg"
                    width={22}
                    alt="TikTok"
                    loading="lazy"
                    placeholder="none"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="Footer__links">
            <small>
              {/* <a href="#">利用規約</a>
              <span className="Footer__delimiter">/</span> */}
              <Link to="/privacy/">プライバシーポリシー</Link>
            </small>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="Footer__section">
            <div className="Footer__inner Footer__inner--list">
              <div className="Footer__innerItem Footer__innerItem--list">
                <h4 className="Footer__innerItemTitle">COMPANY</h4>
                <ul>
                  <li>
                    <button
                      className="Footer__innerItemButton"
                      onClick={() => scrollNavigation("Main")}
                    >
                      トップページ
                    </button>
                  </li>
                  <li>
                    <button
                      className="Footer__innerItemButton"
                      onClick={() => scrollNavigation("Greeding")}
                    >
                      代表あいさつ
                    </button>
                  </li>
                </ul>
              </div>

              <div className="Footer__innerItem Footer__innerItem--list">
                <h4 className="Footer__innerItemTitle">SERVICE</h4>
                <ul>
                  <li>
                    <button
                      className="Footer__innerItemButton"
                      onClick={() => scrollNavigation("Service")}
                    >
                      広告掲載事業
                    </button>
                  </li>
                  <li>
                    <button
                      className="Footer__innerItemButton"
                      onClick={() => scrollNavigation("Service")}
                    >
                      コンサルティング・運用代行事業
                    </button>
                  </li>
                  <li>
                    <button
                      className="Footer__innerItemButton"
                      onClick={() => scrollNavigation("Service")}
                    >
                      インスタグラマー育成事業
                    </button>
                  </li>
                </ul>
              </div>

              <div className="Footer__innerItem Footer__innerItem--list">
                <h4 className="Footer__innerItemTitle">PRICE</h4>
                <ul>
                  <li>
                    <a
                      href={`${process.env.ANIN_PATH}/pdf/price.pdf`}
                      className="Footer__innerItemButton"
                      target="_blank"
                    >
                      サービス料金表
                    </a>
                  </li>
                </ul>
              </div>

              <div className="Footer__innerItem Footer__innerItem--list">
                <h4 className="Footer__innerItemTitle">CONTACT</h4>
                <ul>
                  <li>
                    <button
                      className="Footer__innerItemButton"
                      onClick={() => scrollNavigation("Contact")}
                    >
                      お問い合わせ
                    </button>
                  </li>
                  <li>
                    <button
                      className="Footer__innerItemButton"
                      onClick={() => scrollNavigation("Company")}
                    >
                      会社概要
                    </button>
                  </li>
                </ul>
              </div>

              <div className="Footer__innerItem Footer__innerItem--list Footer__innerItem--map">
                <Map
                  lat={35.46471}
                  lng={139.615588}
                  zoom={16}
                  address="〒220-0005 神奈川県横浜市西区南幸2-20-15"
                />
              </div>
            </div>
          </div>

          <div className="Footer__section">
            <div className="Footer__inner">
              <div className="Footer__innerItem">
                <a href={process.env.ANIN_PATH}>
                  <div className="Footer__logo">
                    <LogoAnin width="162" height="32.7" />
                  </div>
                </a>

                <div className="Footer__snsSection">
                  <ul className="Footer__snsList">
                    {/* <li className="Footer__snsItem">
                      <a href="#dummy" title="Twitter">
                        <StaticImage
                          src="../../images/footer/sp/icon-twitter.svg"
                          width={27}
                          alt="Twitter"
                          loading="lazy"
                        />
                      </a>
                    </li> */}
                    {/* <li className="Footer__snsItem">
                      <a href="#dummy" title="Facebook">
                        <StaticImage
                          src="../../images/footer/sp/icon-facebook.svg"
                          height={27}
                          alt="Facebook"
                          loading="lazy"
                        />
                      </a>
                    </li> */}
                    <li className="Footer__snsItem">
                      <a
                        href="https://instagram.com/anin.beautyyy"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Instagram"
                      >
                        <IconInstagram
                          width={27}
                          height={27}
                        />
                      </a>
                    </li>
                    <li className="Footer__snsItem">
                      <a
                        href="https://www.tiktok.com/@anin.beautyyy"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="TikTok"
                      >
                        <IconTiktok
                          width={22}
                          height={22}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="Footer__copylight">
                <small>© 2022 ANINCo All right reserved</small>
              </div>

              <div className="Footer__links">
                <small>
                  <Link to="/privacy/">プライバシーポリシー</Link>
                  {/* <a href="#">個人情報保護方針</a>
                  <a href="#">利用規約</a> */}
                </small>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </footer>
  )
}

export default Footer
