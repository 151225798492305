import React from "react"
import "./Section.scss"

const Section = props => (
  <section id={props.id} className={`Section ${props.className}`}>
    {props.children}
  </section>
)

export default Section
