import { string } from "prop-types"
import React from "react"

const IconInstagram = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 36 36">
    <g data-name="グループ 7808">
      <path fill="none" d="M0 0h36v36H0z" data-name="長方形 5443"/>
      <g data-name="グループ 7519">
        <g data-name="グループ 7518">
          <path fill="#fff" d="M24.191 5.887H11.628a6.3 6.3 0 0 0-6.286 6.281v12.563a6.3 6.3 0 0 0 6.281 6.281h12.568a6.3 6.3 0 0 0 6.281-6.281V12.168a6.3 6.3 0 0 0-6.281-6.281Zm4.187 18.844a4.192 4.192 0 0 1-4.187 4.187H11.628a4.193 4.193 0 0 1-4.186-4.187V12.168a4.192 4.192 0 0 1 4.186-4.187h12.563a4.192 4.192 0 0 1 4.187 4.187v12.563Z" data-name="パス 3355"/>
        </g>
      </g>
      <g data-name="グループ 7521">
        <g data-name="グループ 7520" transform="translate(23.145 10.074)">
          <circle cx="1.57" cy="1.57" r="1.57" fill="#fff" data-name="楕円形 72"/>
        </g>
      </g>
      <g data-name="グループ 7523">
        <g data-name="グループ 7522">
          <path fill="#fff" d="M17.909 12.168a6.282 6.282 0 1 0 6.281 6.281 6.281 6.281 0 0 0-6.281-6.281Zm0 10.469a4.188 4.188 0 1 1 4.187-4.188 4.188 4.188 0 0 1-4.187 4.188Z" data-name="パス 3356"/>
        </g>
      </g>
    </g>
  </svg>
)

IconInstagram.prototype = {
  width: string,
  height: string,
}

export default IconInstagram