import { string } from "prop-types"
import React from "react"

const IconTiktok = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 22.074 25.125">
    <path fill="#fff" d="M22.062 6.291a6.33 6.33 0 0 1-3.825-1.281A6.332 6.332 0 0 1 15.708 0h-4.1v17.35a3.719 3.719 0 1 1-2.551-3.523V9.661a8.072 8.072 0 0 0-1.183-.087 7.866 7.866 0 0 0-5.911 2.644 7.709 7.709 0 0 0 .348 10.63 8 8 0 0 0 .741.647 7.867 7.867 0 0 0 4.822 1.63 8.072 8.072 0 0 0 1.183-.087 7.84 7.84 0 0 0 4.38-2.191 7.68 7.68 0 0 0 2.3-5.466l-.024-9.171a10.395 10.395 0 0 0 6.364 2.164V6.29h-.012Z" data-name="パス 42701"/>
  </svg>
)

IconTiktok.prototype = {
  width: string,
  height: string,
}

export default IconTiktok