import React from "react"

const IconMenuPink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 25.999 26.004">
    <defs>
      <linearGradient id="a" x1="1" x2="0" y1=".073" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ff6c89"/>
        <stop offset="1" stop-color="#f54875"/>
      </linearGradient>
    </defs>
    <g data-name="グループ 7528">
      <path fill="url(#a)" d="M5096.077 24525.027a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Zm-15 0a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Zm15-15a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Zm-15 0a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Z" data-name="合体 2" transform="translate(-5078.08 -24499.023)"/>
    </g>
  </svg>
)

export default IconMenuPink