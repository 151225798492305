import { string } from "prop-types"
import React from "react"

const LogoAninWhite = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 134.838 27.36">
    <g data-name="グループ 7776">
      <title>ANIN Co.</title>
      <g fill="#fff" data-name="グループ 7567">
        <path d="M.722 26.98h3.306a1.145 1.145 0 0 0 1.1-.76l1.862-4.1h11.364l1.862 4.1a1.092 1.092 0 0 0 1.1.76h3.306a.671.671 0 0 0 .646-.988L13.566.418A.646.646 0 0 0 12.92 0h-.38a.683.683 0 0 0-.646.418L.076 25.992a.671.671 0 0 0 .646.988Zm8.132-9.082 3.724-8.36h.114l3.8 8.36Zm19.874 8.36a.747.747 0 0 0 .722.722h3.458a.722.722 0 0 0 .722-.722V9.918h.038L49.552 27.36h.912a.714.714 0 0 0 .722-.68V1.102a.747.747 0 0 0-.722-.722h-3.5a.722.722 0 0 0-.722.722v15.694h-.038L30.4 0h-.95a.714.714 0 0 0-.722.684Zm29.374 0a.747.747 0 0 0 .722.722h3.534a.747.747 0 0 0 .718-.722V1.102a.747.747 0 0 0-.722-.722h-3.53a.747.747 0 0 0-.722.722Zm11.894 0a.747.747 0 0 0 .722.722h3.458a.722.722 0 0 0 .722-.722V9.918h.038L90.82 27.36h.912a.714.714 0 0 0 .722-.68V1.102a.747.747 0 0 0-.722-.722h-3.5a.722.722 0 0 0-.722.722v15.694h-.034L71.668 0h-.95a.714.714 0 0 0-.722.684Z" data-name="パス 17078"/>
        <path d="M101.116 17.906a9.321 9.321 0 0 0 9.386 9.334 8.924 8.924 0 0 0 6.318-2.522.5.5 0 0 0 .026-.7l-1.612-1.666a.479.479 0 0 0-.65 0 6.24 6.24 0 0 1-3.978 1.482 5.888 5.888 0 0 1-5.824-6.006 5.9 5.9 0 0 1 5.8-6.058 6.151 6.151 0 0 1 4 1.534.416.416 0 0 0 .65 0l1.586-1.638a.483.483 0 0 0-.026-.728A8.8 8.8 0 0 0 110.5 8.52a9.365 9.365 0 0 0-9.384 9.386Zm17.108 3.848a5.439 5.439 0 0 0 5.33 5.486 5.439 5.439 0 0 0 5.33-5.486 5.427 5.427 0 0 0-5.33-5.434 5.427 5.427 0 0 0-5.33 5.434Zm2.834 0a2.544 2.544 0 0 1 2.5-2.626 2.56 2.56 0 0 1 2.5 2.626 2.59 2.59 0 0 1-2.5 2.678 2.573 2.573 0 0 1-2.5-2.678Zm9.49 3.354a2.142 2.142 0 0 0 2.128 2.132 2.164 2.164 0 0 0 2.158-2.132 2.187 2.187 0 0 0-2.158-2.158 2.164 2.164 0 0 0-2.128 2.158Z" data-name="パス 17079"/>
      </g>
    </g>
  </svg>
)

LogoAninWhite.prototype = {
  width: string,
  height: string,
}

export default LogoAninWhite