import React from "react"

const IconMenuWhite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 25.999 26.004">
    <g data-name="グループ 7790">
      <g data-name="グループ 7788">
        <g data-name="グループ 7787">
          <path fill="#fff" d="M17.998 26.004a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Zm-15 0a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Zm15-15a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Zm-15 0a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3Z" data-name="合体 2"/>
        </g>
      </g>
      <g fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" data-name="グループ 7789">
        <path d="m5.575 5.577 14.85 14.85" data-name="線 105"/>
        <path d="m20.425 5.577-14.85 14.85" data-name="線 106"/>
      </g>
    </g>
  </svg>
)

export default IconMenuWhite