import React from "react"

import "./TopTransition.scss"

const TopTransition = props => {
  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <button className="TopTransition" onClick={onClick}>
      <span className="TopTransition__arrow" />
      <span className="TopTransition__text">PAGE TOP</span>
    </button>
  )
}

export default TopTransition
