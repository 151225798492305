import { string } from "prop-types"
import React from "react"

import "./ButtonLink.scss"

const ButtonLink = props => {
  const ButtonLinkTag = props.onClick ? "button" : "a"

  return (
    <ButtonLinkTag
      href={props.href ? props.href : undefined}
      onClick={props.onClick ? props.onClick : undefined}
      className={`ButtonLink${
        props.color ? ` ButtonLink--${props.color}` : ""
      }${props.backdrop ? " ButtonLink--backdrop" : ""}`}
      target={props.target ? "_blank" : undefined}
    >
      <span className="ButtonLink__text">{props.text}</span>
      {props.arrow && <span className="ButtonLink__arrow" />}
    </ButtonLinkTag>
  )
}

ButtonLink.prototype = {
  href: string,
  text: string,
  color: "blue" | "red",
}

export default ButtonLink
