import { string } from "prop-types"
import React from "react"

import "./Title.scss"

const Title = props => (
  <div className="Title">
    <h2 className="Title__title">{props.title}</h2>
    {props.text && <span className="Title__text">{props.text}</span>}
  </div>
)

Title.prototype = {
  title: string,
  text: string,
}

export default Title
